import React from "react";
import Container from "./ui/Container";
import Title from "./ui/Title";
import Text from "./ui/Text";
import Line from "./ui/Line";
import PatternById from "./ui/PatternById";

export default function Overview() {
  return (
    <div className="relative pt-20 lg:pt-40 lg:pb-40" id="overview">
      <div className="hidden lg:block">
        <PatternById
          id="halfCircle"
          width={128}
          lgOnly
          position={{ top: "-10%" }}
        />
        <PatternById
          id="overviewRight"
          width={400}
          lgOnly
          position={{ right: "5%", top: "15%" }}
        />
      </div>
      <Container className="relative">
        <div className="hidden lg:block">
          <PatternById id="overviewLeft" width={256} className="ml-16" />
        </div>

        <div className="w-full lg:w-2/3 mx-auto mt-0 lg:-mt-64">
          <div className="flex">
            <div className="flex flex-col items-center mb-10">
              {/* <div className="hidden md:block h-32">
                 <Line
                  className="h-20 mb-20"
                  lgOnly
                  position={{ left: "50%" }}
                /> 
              </div> */}
              <div className="text-center">
                <Title>2 Days, 2000+ Devs</Title>
                <Text className="md:px-24">
                  React Day Bangalore is a two day conference fully packed with
                  amazing talks and panel discussions on topics related to React
                  and React Native. You get an opportunity to network with 2000+
                  enthusiastic react developers at this conference.
                </Text>
                {/* <Text className="md:px-24">
                  We are coming back with second edition of our conference. We
                  are working to put together amazing lineup of talks and panel
                  discussions on topics related to React and React Native.
                </Text> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
