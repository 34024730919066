import React from "react";

export default function TextureTitle({ title }) {
  return (
    <div className="flex items-start leading-tight md:leading-none text-7xl font-bold tracking-wide md:tracking-normal lg:text-8xl">
      <h1 data-text={title} className="texture uppercase -ml-5">
        {title}
      </h1>
    </div>
  );
}
