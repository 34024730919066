import * as React from "react";

function TwitterIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <path
        fill="#FFF"
        d="M31.939 6.092a13.31 13.31 0 01-3.767 1.033 6.613 6.613 0 002.884-3.631 13.55 13.55 0 01-4.169 1.579A6.56 6.56 0 0015.539 9.55c0 .52.06 1.02.169 1.499-5.453-.257-10.287-2.876-13.521-6.835a6.432 6.432 0 00-.888 3.3 6.56 6.56 0 002.917 5.461 6.554 6.554 0 01-2.971-.821v.081a6.564 6.564 0 005.261 6.436 6.663 6.663 0 01-2.949.113 6.582 6.582 0 006.139 4.556 13.156 13.156 0 01-8.136 2.807c-.52 0-1.039-.031-1.56-.089a18.657 18.657 0 0010.076 2.945c12.072 0 18.665-9.995 18.665-18.648 0-.279 0-.56-.02-.84 1.281-.919 2.4-2.08 3.28-3.397z"
      />
    </svg>
  );
}

const MemoTwitterIcon = React.memo(TwitterIcon);
export default MemoTwitterIcon;
