import React from "react";

export default function Logos({ children, className, imageWrapperClass }) {
  const wrapperClass = `${imageWrapperClass} flex items-center justify-center px-3 md:px-5 py-2`;
  const sponsors = React.Children.map(children, (child) => (
    <div className={wrapperClass}>{child}</div>
  ));
  return (
    <div className={className}>
      <div className="flex flex-wrap items-center">{sponsors}</div>
    </div>
  );
}
