import React from "react";
import Container from "./ui/Container";
import ShadowTitle from "./ui/ShadowTitle";
import TextureTitle from "./ui/TextureTitle";
import PatternById from "./ui/PatternById";
import LogoById from "./ui/LogoById";
import Text from "./ui/Text";
import classNames from "classnames";
import { useIsMobile } from "./ui/hooks";
import PrimaryButton from "./ui/PrimaryButton";

// const sponsors = [
//   "archimydes",
//   "atlantis",
//   "auth0",
//   "cleartax",
//   "geekyants",
//   "goibibo",
//   "groww",
//   "harness",
//   "hasura",
//   // "hotstar",
//   "openhouse",
//   "quolum",
//   "razorpay",
//   "swiggy",
//   "xactly",
// ];

const logosPerRowDesktop = {
  platinum: 2,
  gold: 3,
  silver: 4,
  bronze: 4,
  "community partners": 4,
};

const logosPerRowMobile = {
  platinum: 1,
  gold: 2,
  silver: 3,
  bronze: 3,
  "community partners": 3,
};

const platinumSponsors = ["cars24", "mercari", "postman", "storyblok"];
const goldSponsors = [
  "groww",
  "meesho",
  "porter",
  "quolum",
  "smallcase",
  "xactly",
];
const silverSponsors = ["harness", "razorpay", "turing"];
const bronzeSponsors = ["assembly", "geekyants", "swiggy"];
const communityPartners = ["devfolio", "peerlist", "recro", "react-india"];

function SponsorsSection({ tier, sponsors, id }) {
  const isMobile = useIsMobile();
  const logosPerRow = isMobile
    ? logosPerRowMobile[tier]
    : logosPerRowDesktop[tier];
  const partialLogosLength = sponsors.length % logosPerRow;
  const emptyLogosLength =
    partialLogosLength === 0 ? 0 : logosPerRow - partialLogosLength;
  if (!sponsors.length) {
    return null;
  }
  const layoutClass = classNames(
    "grid justify-start items-center w-full py-2 lg:py-4 gap-3 lg:gap-8",
    {
      "grid-cols-1 lg:grid-cols-2 lg:w-4/5": tier === "platinum",
      "grid-cols-2 lg:grid-cols-3 lg:w-3/4": tier === "gold",
      "grid-cols-3 lg:grid-cols-4 lg:w-3/4":
        tier === "bronze" || tier === "silver", // || tier === "community partners",
      "grid-cols-3 lg:w-1/2": tier === "community partners",
    }
  );

  return (
    <Container
      className="mb-5 lg:mb-10 py-3 lg:py-6"
      backgroundClassName="bg-white"
      id={id}
    >
      <h1 className="uppercase text-xl md:text-2xl py-5 tracking-wide">
        {tier}
      </h1>
      <div className={layoutClass}>
        {sponsors.map((sponsor, i) => (
          <LogoById key={sponsor} id={sponsor} className="cursor-pointer" />
        ))}
        {Array(emptyLogosLength)
          .fill()
          .map((_, i) => (
            <div key={i} />
          ))}
      </div>
    </Container>
  );
}

function SponsorText() {
  return (
    <div className="w-full py-3 lg:py-6">
      <Text>
        At React Day Bangalore you get a chance to make your brand visible to
        2000+ passionate React and React Native developers. If you are
        interested in sponsoring this event, please write to us at{" "}
        <a
          href="mailto:reactday@neostack.com"
          className="font-bold text-blue underline"
        >
          reactday@neostack.com
        </a>
      </Text>
      <div className="my-3 lg:my-6">
        <PrimaryButton href="mailto:reactday@neostack.com">
          Become a Sponsor
        </PrimaryButton>
      </div>
    </div>
  );
}

export default function Sponsors() {
  return (
    <div className="relative">
      <Container id="sponsors" className="py-2 md:py-10">
        <div className="hidden lg:grid grid-cols-2">
          <div className="mr-6">
            <PatternById id="sponsorShapes" width={256} className="-ml-10" />
            <TextureTitle title="Sponsors" />
            <SponsorText />
          </div>
          {/* <PatternById id="mendu" width={400} /> */}
          <PatternById id="coffee" width={512} loading="eager" />
        </div>
        <div className="lg:hidden block">
          <ShadowTitle>Sponsors</ShadowTitle>
          <SponsorText />
        </div>
      </Container>
      <div></div>
      <SponsorsSection tier="platinum" sponsors={platinumSponsors} />
      <SponsorsSection tier="gold" sponsors={goldSponsors} />
      <SponsorsSection tier="silver" sponsors={silverSponsors} />
      <SponsorsSection tier="bronze" sponsors={bronzeSponsors} />
      <SponsorsSection
        tier="community partners"
        sponsors={communityPartners}
        id="community-partners"
      />

      {/* <div className="text-lg md:text-2xl mb-12 mt-12">
        <p>
          If you are interested in sponsoring React Day, reach out to us at{" "}
          <a
            href="mailto:reactday@neostack.com"
            className="font-bold text-blue underline"
          >
            reactday@neostack.com
          </a>
          .
        </p>
      </div> */}
    </div>
  );
}
