import React from "react";
import classnames from "classnames";

function Title({ children, className }) {
  const titleClass = classnames(
    "text-white uppercase tracking-wide leading-normal font-bold",
    className
  );
  return <h1 className={titleClass}>{children}</h1>;
}

export default function ShadowTitle({ children, className }) {
  return (
    <Title className={classnames("regular-shadow text-6xl", className)}>
      {children}
    </Title>
  );
}

// function LargeShadowTitle({ children, className }) {
//   return (
//     <Title
//       className={classnames(
//         "texture-shadow text-3xl md:text-7xl lg:text-10xl",
//         className
//       )}
//     >
//       {children}
//     </Title>
//   );
// }

// export default function ShadowTitle({ children, className }) {
//   return (
//     <>
//       <SmallShadowTitle className={classnames("block md:hidden", className)}>
//         {children}
//       </SmallShadowTitle>
//       <LargeShadowTitle className={classnames("hidden md:block", className)}>
//         {children}
//       </LargeShadowTitle>
//     </>
//   );
// }
