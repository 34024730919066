import React from "react";
import PersonById from "./ui/PersonById";
import TwoColumnSection from "./ui/TwoColumnSection";

export default function MCs() {
  return (
    <TwoColumnSection title="emcees" id="emcees">
      <div className="flex-1">
        <PersonById id="megha" />
      </div>
      <div className="flex-1">
        <PersonById id="tanisha" />
      </div>
    </TwoColumnSection>
  );
}
