import React from "react";
import NeostackImage from "../images/neostack-logo.png";
import ReactifyImage from "../images/reactify-logo.png";
import Container from "./ui/Container";
import Logos from "./ui/Logos";

export default function Organizers() {
  return (
    <Container
      id="organizers"
      className="py-2 md:py-10 pt-10"
      backgroundClassName="bg-white"
    >
      <div className="pb-20">
        <h2 className="uppercase font-bold text-4xl md:text-5xl pb-5">
          Organizers
        </h2>
        <Logos className="h-20" imageWrapperClass="w-1/2 md:w-1/3 my-3">
          <a href="https://neostack.com" className="cursor-pointer">
            <img src={NeostackImage} />
          </a>
          <a href="https://twitter.com/reactify_in" className="cursor-pointer ">
            <img src={ReactifyImage} />
          </a>
        </Logos>
      </div>
    </Container>
  );
}
