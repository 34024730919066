import { TwitterButton, YouTubeButton, DiscordButton } from "./SocialButtons";
import Container from "./ui/Container";
import PatternById from "./ui/PatternById";
import { useWindowSize } from "./ui/hooks";
import Text from "./ui/Text";

export default function Community() {
  const { windowWidth } = useWindowSize();

  return (
    <Container
      id="community"
      className="py-2 md:py-10 pt-10"
      backgroundClassName="bg-white"
    >
      <div className="pb-40 relative">
        <h2 className="uppercase font-bold text-3xl lg:text-5xl pb-5">
          Join our Community
        </h2>
        <Text>{/*Stay connected */}</Text>
        <div className="block lg:flex">
          <TwitterButton className="mr-0 lg:mr-4 my-4" />
          <DiscordButton className="mr-0 lg:mr-4 my-4" />
          <YouTubeButton className="mr-0 lg:mr-4 my-4" />
        </div>
        {windowWidth && (
          <PatternById
            id="footerShapes"
            width={windowWidth * 0.15}
            lgOnly
            position={{ bottom: -75, right: 0 }}
          />
        )}
      </div>
    </Container>
  );
}
