import React from "react";
import classNames from "classnames";

interface IProps {
  className?: string;
  children: React.ReactNode;
}

export default function Text({ className, children }: IProps) {
  return (
    <p
      className={classNames(
        className,
        "text-xl md:text-2xl leading-normal py-4"
      )}
    >
      {children}
    </p>
  );
}
