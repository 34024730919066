import { day1, day2 } from "../../data/schedule";
import classnames from "classnames";
import Link from "next/link";
import people from "../../data/people";

function ScheduleButton({ day, active }) {
  let activeButtonText =
    day === 1 ? "Day 1 - June 08, 2022" : "Day 2 - June 09, 2022";
  let otherButtonText = day === 1 ? "Day 2" : "Day 1";
  let buttonText = active ? activeButtonText : otherButtonText;

  let commonClass = classnames(
    "px-6 py-1 rounded-full mr-4",
    "outline-none focus:outline-none"
  );
  let activeButtonClass = classnames(
    commonClass,
    "bg-gray-300 text-white cursor-default"
  );
  let buttonClass = classnames(
    commonClass,
    "bg-gray-200 text-blue cursor-pointer"
  );
  let className = active ? activeButtonClass : buttonClass;
  let url = day === 1 ? "/#schedule/day2" : "/#schedule/day1";
  if (active) {
    return <a className={className}>{buttonText}</a>;
  }
  return (
    <Link href={url}>
      <a className={className}>{buttonText}</a>
    </Link>
  );
}

function Label({ text }) {
  return (
    <div
      className="uppercase text-gray-300 py-1 text-sm md:text-lg"
      style={{ borderColor: "#E9D4C4" }}
    >
      {text}
    </div>
  );
}

function Panel({ item }) {
  const speakerNames =
    item.speakers &&
    item.speakers
      .map((s) => {
        const speaker = people.find((p) => p.id === s);
        return `${speaker.firstName} ${speaker.lastName}`;
      })
      .join(", ");
  return (
    <div>
      <div className="font-bold text-lg md:text-xl">{item.title}</div>
      <div className="block md:flex">
        <div className="mr-4 py-1 text-md md:text-lg">{speakerNames}</div>
        <Label text={item.type}></Label>
      </div>
    </div>
  );
}

function Break({ item }) {
  return <div className="font-bold text-xl flex items-center">Break</div>;
}

function Talk({ item }) {
  const speaker = item.speaker && people.find((p) => p.id === item.speaker);
  if (!speaker) {
    return null;
  }
  return (
    <>
      <div>
        <img
          className="w-16 rounded-full mr-4 hidden md:block"
          src={speaker.avatar.src}
        />
      </div>
      <div className="flex-1">
        <div className="font-bold text-lg md:text-xl">{item.title}</div>
        <div className="block md:flex ">
          <div className="mr-0 md:mr-8 py-1 text-md md:text-lg">
            {speaker.firstName} {speaker.lastName}
          </div>
          <Label text={item.type}></Label>
        </div>
      </div>
    </>
  );
}

function ScheduleItem({ item, day }) {
  const date = day === 1 ? "June 08" : "June 09";
  const timeClass = classnames(
    "w-24 md:w-48 mr-2 md:mr-4 mb-2 md:mb-4 py-6 px-2 md:px-4 text-right",
    {
      "bg-gray-150": item.type !== "break",
      "bg-gray-400": item.type === "break",
    }
  );
  const detailsClass = classnames(
    "bg-gray-200 mb-2 md:mb-4 py-6 px-4 md:px-8 flex-1 flex",
    {
      "bg-gray-200": item.type !== "break",
      "bg-gray-400": item.type === "break",
    }
  );

  return (
    <div className="flex">
      <div className={timeClass}>
        <div className="font-bold text-md md:text-xl">{item.time}</div>
        <div className="text-sm md:text-md">{date}</div>
      </div>
      <div className={detailsClass}>
        {item.type === "break" ? <Break /> : null}
        {item.type === "full talk" || item.type === "lightning talk" ? (
          <Talk item={item} />
        ) : null}
        {item.type === "panel discussion" ? <Panel item={item} /> : null}
      </div>
    </div>
  );
}

export default function ScheduleList({ day }) {
  const schedule = day === 1 ? day1 : day2;

  const id = day === 1 ? "schedule/day1" : "schedule/day2";
  return (
    <div className="relative mt-10">
      <div id={id} className="absolute -mt-20 md:-mt-28"></div>
      <div className="flex items-center my-10">
        <ScheduleButton active={day === 1} day={day} />
        <ScheduleButton active={day === 2} day={day} />
        <div className="h-0 flex-1 border border-gray-200" />
      </div>
      <div className="">
        {schedule.map((item, index) => (
          <ScheduleItem key={index} item={item} day={day} />
        ))}
      </div>
    </div>
  );
}
