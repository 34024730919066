import Container from "../components/ui/Container";
import Organizers from "../components/Organizers";
import Partners from "../components/Partners";
import Speakers from "../components/Speakers";
import MCs from "../components/MCs";
import Hosts from "../components/Hosts";
import Sponsors from "../components/Sponsors";
import Schedule from "../components/schedule";
import Overview from "../components/Overview";
import HeroBanner from "../components/HeroBanner";
import Layout from "../components/ui/Layout";
import Community from "../components/Community";

export default function Home() {
  return (
    <Layout>
      <div className="min-h-screen">
        <HeroBanner />
        <Overview />
        <Speakers />
        <Container className="py-12 md:pt-32">
          <MCs />
          {/* <Hosts /> */}
        </Container>
        <Schedule />
        <Sponsors />
        {/* <Partners /> */}
        <Organizers />
        <Community />
      </div>
    </Layout>
  );
}
