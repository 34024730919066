import Container from "../ui/Container";
import ShadowTitle from "../ui/ShadowTitle";
import TextureTitle from "../ui/TextureTitle";
import ScheduleList from "./ScheduleList";

export default function Schedule() {
  return (
    <div>
      <Container id="schedule" className="py-8 md:py-24">
        <div className="hidden lg:grid grid-cols-2">
          <TextureTitle title="Schedule" />
        </div>
        <div className="lg:hidden flex justify-center">
          <ShadowTitle>Schedule</ShadowTitle>
        </div>
        <ScheduleList day={1} />
        <ScheduleList day={2} />
      </Container>
    </div>
  );
}
