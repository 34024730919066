export const day1 = [
  {
    time: "10:00 AM",
    title: "React 18: New features and updates",
    type: "full talk",
    speaker: "neha",
  },
  {
    time: "10:30 AM",
    title: "One step back, two steps forward",
    type: "full talk",
    speaker: "kamlesh",
  },
  {
    time: "11:00 AM",
    title: "Design tokens: Scaling designs in your UI library",
    type: "lightning talk",
    speaker: "shashwat",
  },
  {
    time: "11:10 AM",
    type: "break",
  },
  {
    time: "11:30 AM",
    title: "The Perfect Dark Mode",
    type: "full talk",
    speaker: "sreetam",
  },
  {
    time: "12:00 PM",
    title: "What makes a good UI library",
    type: "full talk",
    speaker: "dhaiwat",
  },
  {
    time: "12:30 PM",
    title: "SEO Performant React Apps",
    type: "lightning talk",
    speaker: "kiran2",
  },
  {
    time: "12:40 PM",
    title: 'The subtle art of "Subtle" loading',
    type: "lightning talk",
    speaker: "nikhil",
  },
  {
    time: "12:50 PM",
    title: "Intelligent lazy loading in React.js",
    type: "lightning talk",
    speaker: "nikhil2",
  },
  {
    time: "01:00 PM",
    type: "break",
  },
  {
    time: "01:50 PM",
    title:
      "Solving coverage issue through async reducers and reducing app bundle size in process​",
    type: "lightning talk",
    speaker: "mohit",
  },
  {
    time: "02:00 PM",
    title: "Zust do it! Simplified state management using Zustand",
    type: "full talk",
    speaker: "sai",
  },
  {
    time: "02:30 PM",
    title: "Concurrent React ♥️ 3D",
    type: "full talk",
    speaker: "shriram",
  },
  {
    time: "03:00 PM",
    title: "Building Collaborative applications in React",
    type: "full talk",
    speaker: "ameer",
  },
  {
    time: "03:30 PM",
    type: "break",
  },
  {
    time: "03:50 PM",
    title: "Finding memory leaks with Chrome Dev tools​",
    type: "lightning talk",
    speaker: "zubair",
  },
  {
    time: "04:00 PM",
    title:
      "Accelerating UI Engg and Improving Customer Experience using Developer Toolkit​",
    type: "full talk",
    speaker: "navya",
  },
  {
    time: "04:30 PM",
    title: "Cypress - The React Application End To End Testing Tool",
    type: "full talk",
    speaker: "rahul2",
  },
  {
    time: "05:00 PM",
    title: "   TBD",
    type: "panel discussion",
    speakers: [],
  },
];

export const day2 = [
  {
    time: "10:00 AM",
    title: "Improving performance for React & Electron Apps",
    type: "full talk",
    speaker: "kamal",
  },
  {
    time: "10:30 AM",
    title: "Cracking a web based metaverse experience with WebRTC",
    type: "full talk",
    speaker: "shivam",
  },
  {
    time: "11:00 AM",
    title: "Creating Experience in MetaVerse",
    type: "lightning talk",
    speaker: "suprabha",
  },
  {
    time: "11:10 AM",
    type: "break",
  },
  {
    time: "11:30 AM",
    title: "The Frontend Framework of our Dreams?",
    type: "full talk",
    speaker: "saurabh",
  },
  {
    time: "12:00 PM",
    title: "If you know React, you'll love Solid",
    type: "lightning talk",
    speaker: "aditya",
  },
  {
    time: "12:10 PM",
    title: "React conditional props with Typescript",
    type: "lightning talk",
    speaker: "gaurav",
  },
  {
    time: "12:20 PM",
    title: "How Rescript changed the way we think ?",
    type: "lightning talk",
    speaker: "prateek",
  },
  {
    time: "01:00 PM",
    type: "break",
  },
  {
    time: "1:50 PM",
    title: "Handling Dynamic links 101",
    type: "lightning talk",
    speaker: "manohar",
  },
  {
    time: "02:00 PM",
    title: "Trying to understand new architecture of react-native with JSI",
    type: "full talk",
    speaker: "aditya2",
  },
  {
    time: "02:30 PM",
    title: "Offline workflow in React Native with WatermelonDB",
    type: "full talk",
    speaker: "shirsh",
  },
  {
    time: "03:00 PM",
    title: "Developing native iOS, android and flutter SDKs in React Native",
    type: "full talk",
    speaker: "palash",
  },
  {
    time: "03:30 PM",
    type: "break",
  },
  {
    time: "03:50 PM",
    title: "Native-like Experience with Reactive WebView",
    type: "lightning talk",
    speaker: "parth",
  },
  {
    time: "04:00 PM",
    title: "React Native Everywhere",
    type: "full talk",
    speaker: "taz",
  },
  {
    time: "04:30 PM",
    title: "React Native A11y Basics",
    type: "full talk",
    speaker: "nivedita",
  },

  {
    time: "05:00 PM",
    title: "TBD",
    type: "panel discussion",
    speakers: [],
  },
];

// export const day1 = [
//   {
//     time: "10:00 AM",
//     title: "Destructuring React",
//     type: "full talk",
//     speaker: "sudhanshu",
//   },
//   {
//     time: "10:30 AM",
//     title: "Event Delegation in React 17",
//     type: "full talk",
//     speaker: "ankita",
//   },
//   {
//     time: "11:00 AM",
//     title: "Failing Gracefully: Error Boundaries in React",
//     type: "lightning talk",
//     speaker: "dane",
//   },
//   {
//     time: "11:10 AM",
//     type: "break",
//   },
//   {
//     time: "11:30 AM",
//     title: "Road to a better UX with Suspense and Concurrent UI",
//     type: "full talk",
//     speaker: "nikhil",
//   },
//   {
//     time: "12:00 PM",
//     title: "Thinking of UI as a State Machine",
//     type: "full talk",
//     speaker: "nitish",
//   },
//   {
//     time: "12:30 PM",
//     title: "Machina and its use case with Server Driven UI",
//     type: "lightning talk",
//     speaker: "dipti",
//   },
//   {
//     time: "12:40 PM",
//     title: "Untangling reducers into reusable patterns",
//     type: "lightning talk",
//     speaker: "thiyagaraj",
//   },
//   { time: "12:50 PM", type: "break" },
//   {
//     time: "01:50 PM",
//     title: "Virtualization in React",
//     type: "lightning talk",
//     speaker: "anshuman",
//   },
//   {
//     time: "02:00 PM",
//     title: "Building smooth interactive interfaces with React",
//     type: "full talk",
//     speaker: "sid",
//   },
//   {
//     time: "02:30 P.M",
//     title: "End to End Typing",
//     type: "full talk",
//     speaker: "vivek",
//   },
//   { time: "03:00 PM", type: "break" },
//   {
//     time: "03:30 PM",
//     title: "Invincible React States with Domain Driven Design",
//     type: "full talk",
//     speaker: "prateek",
//   },
//   // {
//   //   time: "04:00 PM",
//   //   title: "Reason outside the browser",
//   //   type: "full talk",
//   //   speaker: "manas",
//   // },
//   { time: "04:30 PM", type: "break" },
//   {
//     time: "05:00 PM",
//     title: "Web Performance",
//     type: "panel discussion",
//     speakers: ["prateekb", "pavithra", "rahul", "ankeet"],
//   },
// ];

// export const day2 = [
//   {
//     time: "10:00 AM",
//     title: "Next-gen Frontend tooling",
//     type: "full talk",
//     speaker: "pavithra",
//   },
//   {
//     time: "10:30 AM",
//     title: "The big problems with Visual Testing",
//     type: "full talk",
//     speaker: "aditya",
//   },
//   {
//     time: "11:00 AM",
//     title: "Building a Green Screen with React and HTML Elements",
//     type: "lightning talk",
//     speaker: "sreetam",
//   },
//   { time: "11:10 AM", type: "break" },
//   {
//     time: "11:30 AM",
//     title: "Adding the accessibility in your React Apps",
//     type: "full talk",
//     speaker: "neha",
//   },
//   {
//     time: "12:00 PM",
//     title: "WebAssembly: Re-defining capabilities of the Web",
//     type: "full talk",
//     speaker: "naman",
//   },
//   {
//     time: "12:30 PM",
//     title: "The DEV-inci Code for Design",
//     type: "lightning talk",
//     speaker: "tanisha",
//   },
//   {
//     time: "12:40 PM",
//     title: "IoT Based Browser Authentication",
//     type: "lightning talk",
//     speaker: "sooraj",
//   },
//   { time: "12:50 PM", type: "break" },
//   {
//     time: "01:45 PM",
//     title: "React Native",
//     type: "panel discussion",
//     speakers: ["sanket", "aman", "evan", "satyajit"],
//   },

//   {
//     time: "02:30 PM",
//     title: "Is React Native *truly* Cross Platform",
//     type: "full talk",
//     speaker: "muskein",
//   },
//   {
//     time: "03:00 PM",
//     title: "Improve React Native performance with Hermes",
//     type: "lightning talk",
//     speaker: "patro",
//   },
//   { time: "03:10 PM", type: "break" },
//   {
//     time: "03:30 PM",
//     title:
//       "Building a universal & accessible component library for the native and the web",
//     type: "full talk",
//     speaker: "sanket",
//   },
//   {
//     time: "04:00",
//     title: "Optimizing million re-renders in React Native",
//     type: "full talk",
//     speaker: "rajat",
//   },
//   { time: "04:30 PM", type: "break" },
//   {
//     time: "05:00 PM",
//     title: "Finding your first job",
//     type: "panel discussion",
//     speakers: ["kamlesh", "vishwa", "saurabh", "megha"],
//   },
// ];
