import React from "react";
import Link from "next/link";

export default function Button({ to, href, children, className, disabled }) {
  if (to) {
    return (
      <Link href={to}>
        <a className={className} disabled={disabled}>
          {children}
        </a>
      </Link>
    );
  }
  if (href) {
    return (
      <a href={href} className={className} disabled={disabled}>
        {children}
      </a>
    );
  }
  return (
    <button className={className} disabled={disabled}>
      {children}
    </button>
  );
}
