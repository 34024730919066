import React from "react";
import ShadowTitle from "./ShadowTitle";
import TextureTitle from "./TextureTitle";

export default function TwoColumnSection({ children, title, id }) {
  return (
    <div className="py-4 lg:py-0" id={id}>
      <div className="block text-center lg:hidden mb-4">
        <ShadowTitle>{title}</ShadowTitle>
      </div>
      <div className="w-full lg:w-3/4 mx-auto lg:grid grid-cols-3 gap-4">
        <div className="hidden lg:flex col-span-1 justify-end items-center pr-10 border-r-4">
          <TextureTitle title={title} />
        </div>
        <div className="w-full col-span-2 block md:grid grid-cols-2">
          {children}
        </div>
      </div>
    </div>
  );
}
