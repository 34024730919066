import React from "react";
import classNames from "classnames";

function PatternWrapper({ position, lgOnly, children }) {
  return (
    <div
      className={classNames("absolute", {
        "hidden md:block": lgOnly,
        "block md:hidden": !lgOnly,
      })}
      style={position}
    >
      {children}
    </div>
  );
}

export default function Line({ className, lgOnly, position }) {
  const line = (
    <div className={classNames("h-20 w-0 border-l-4 border-gray", className)} />
  );
  if (position) {
    return (
      <PatternWrapper lgOnly={lgOnly} position={position}>
        {line}
      </PatternWrapper>
    );
  }
  return line;
}
