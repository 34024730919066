import * as React from "react";

function YouTubeIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <path
        fill="#FFF"
        d="M31.327 8.273a4.026 4.026 0 00-2.756-2.777l-.028-.007c-2.493-.668-12.528-.668-12.528-.668s-10.009-.013-12.528.668A4.026 4.026 0 00.71 8.245l-.007.028C.26 10.554.007 13.176.007 15.858v.163-.008.126c0 2.682.253 5.304.737 7.845l-.041-.26a4.026 4.026 0 002.756 2.777l.028.007c2.491.669 12.528.669 12.528.669s10.008 0 12.528-.669a4.026 4.026 0 002.777-2.756l.007-.028c.425-2.233.668-4.803.668-7.429l-.001-.297v.015l.001-.31c0-2.626-.243-5.196-.708-7.687l.04.258zM12.812 20.801V11.21l8.352 4.803z"
      />
    </svg>
  );
}

const MemoYouTubeIcon = React.memo(YouTubeIcon);
export default MemoYouTubeIcon;
