import React from "react";
import Image from "next/image";
import { GithubIcon, LinkedinIcon, TwitterIcon } from "../Icons";
import people from "../../data/people";

export function Person({
  id,
  avatar,
  firstName,
  lastName,
  title,
  company,
  twitter,
  github,
  linkedin,
}) {
  return (
    <div className="p-5">
      <Image
        alt={id}
        src={avatar.src}
        width={avatar.width || 600}
        height={avatar.height || 600}
      />
      <div className="text-2xl">
        <span className="font-bold">{firstName}</span> <span>{lastName}</span>
      </div>
      <div className="text-xl">{title}</div>
      <div className="text-xl">{company}</div>
      <div className="flex">
        {twitter && <TwitterIcon url={twitter} />}
        {linkedin && <LinkedinIcon url={linkedin} />}
        {github && <GithubIcon url={github} />}
      </div>
    </div>
  );
}

export default function PersonById({ id }) {
  const person = people.find((p) => p.id === id);
  if (!person) {
    return null;
  }

  return <Person {...person} />;
}
